<template>
<div>
    <div class="right-menu shipping-right">
        <subHeader pageName="THEME MANAGEMENT"/>

        <tutorialsAction />

        <div class="db_top">
            <div class="db-container">
                <div class="onboardingRightsideBlock themeManagementBlock">
                    <div class="onboardingContentBox">
                        <div class="activeThemeWrap">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="themeManagementDashboardBlock selectedTheme">
                                        <div class="themeProductBg">
                                            <span class="themeProductImage" :style="`background-image:url(${base_image_url + currentTheme.image}) !important`"></span>
                                            <p>Active Theme</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="activeDetailContent">
                                        <h2>{{ currentTheme.name }}</h2>
                                        <p>{{ currentTheme.description }}</p>
                                        <div class="themeVersionTxt">
                                            <h5>Current Version: <span>{{settings.theme_version}}</span></h5>
                                            <p class="uptodateVerTxt" v-if="settings.theme_hash == currentTheme.theme_hash">
                                                Your Theme is upto date
                                            </p>
                                            <p class="updateAvailableTxt" v-else-if="themeUpdateLoader">
                                                Your theme is being updated. Please wait.
                                            </p>
                                            <p class="updateAvailableTxt" v-else>
                                                Theme Update Available. Please Update Your Theme 
                                                <a href="" @click.prevent="themeChangeOperations('update-theme')">
                                                    Update Theme
                                                </a>
                                            </p>
                                        </div>
                                        <div class="liveDemoBtn">
                                            <a :href="currentTheme.demo_link" target="_blank">Live Demo</a>
                                            <button @click.prevent="openVideoPreview(currentTheme)">Video Demo</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- theme loader block starts here -->
                        <div class="themeManagementDashboard" v-if="themesLoading">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="themeManagementDashboardBlock selectedTheme">
                                        <vue-skeleton-loader
                                            type="square"
                                            :width="716"
                                            :height="550"
                                            animation="fade"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="themeManagementDashboardBlock selectedTheme">
                                        <vue-skeleton-loader
                                            type="square"
                                            :width="716"
                                            :height="550"
                                            animation="fade"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- theme loader block ends here -->

                        <!-- themes block starts here -->
                        <div class="themeManagementDashboard" v-else>
                            <div class="row">
                                <div class="col-md-4" v-for="(theme,index) in availableThemes" :key="index">
                                    <div class="themeManagementDashboardBlock selectedTheme">
                                        <a href="">
                                            <div class="themeProductBg">
                                                <span class="themeProductImage" :style="`background-image:url(${base_image_url + theme.image})`"></span>
                                                <p v-show="selectedTheme && selectedTheme.id == theme.id">Selected Theme</p>
                                                <p v-show="currentTheme && currentTheme.id == theme.id">Current Theme</p>
                                            </div>
                                        </a>
                                        <div class="themeTitleRow">
                                            <div class="themeTitleHeading" >
                                                <h5 id="theme-name">{{ theme.name }}</h5>
                                            </div>
                                            <div class="themeActiveBtns">
                                                <a id="theme-selection" href="#." v-show="(currentTheme && currentTheme.id != theme.id && generatedRequest == null)"  @click.prevent="selectTheme(theme)">Select</a>
                                                <a :href="theme.demo_link" id="theme-demo" target="_blank">Live Demo</a>
                                                <button @click.prevent="openVideoPreview(theme)">Video Demo</button>
                                            </div>
                                        </div>
                                        <p class="infoTxtBlock" v-show="generatedRequest != null && generatedRequest.requested_theme_id == theme.id">Your request has been submitted against this theme. Admin will respond within few hours.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- themes block ends here -->

                        <!-- action buttons block starts here -->
                        <div class="inboardingSubmitBtn theme">
                            <loaderBtn v-if="loader"/>
                            <button 
                                @click.prevent="generatedRequest ? themeChangeOperations('cancel-request') : themeChangeOperations('generate-request')" 
                                id="generate-theme-request" 
                                :disabled="tourIsActive"
                                v-else 
                            >
                                {{ generatedRequest ? 'Cancel Theme Change Request' : 'Generate Theme Change Request' }}
                            </button>
                        </div>
                        <!-- action buttons block ends here -->
                        
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- video preview modal starts here-->
    <div class="modalBg" :class="videoDialogVisible ? 'modal-active' : ''">
        <div class="modalLayers" @click.prevent="closeVideoPreviewHandle"></div>
        <div class="modalBody">
            <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%">
                <div class="productZoomImg" ref="themeVideoDemoWrap">
                    <iframe ref="themeVideoDemoIframe" width="1060" height="550" src="" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>
            <div class="modalCloseIcon">
                <button @click.prevent="closeVideoPreviewHandle">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                        <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                    </svg>
                </button>
            </div>
        </div>
    </div>
    <!-- video preview modal ends here-->

    <!--vue tour-->
    <v-tour name="themeManagementTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>

</div>
</template>
  
<script>
import {MessageBox, Message} from 'element-ui'
import subHeader from "@/components/subHeader.vue";
import { mapGetters } from 'vuex';
import tutorialsAction from "@/components/tutorialsAction.vue";
import loaderBtn from "@/components/loaderBtn.vue";

export default {
    metaInfo() {
        return {
            title: 'Theme Management | Themes | YeetCommerce',
        };
    },
    data() {
        return {
            base_image_url: process.env.VUE_APP_STORE_THEME_URL,
            availableThemes:[],
            selectedTheme:[],
            currentTheme:[],
            themeUpdateLoader:false,
            loader:false,
            generatedRequest:null,

            //theme video
            videoDialogVisible:false,

            //vue-tour
            tourOptions: {
                useKeyboardNavigation: false,
                labels: {
                    buttonSkip: 'Skip Tour',
                    buttonPrevious: 'Previous',
                    buttonNext: 'Next',
                    buttonStop: 'Finish Tour'
                }
            },
            tourCallbacks: {
                onPreviousStep: this.handlePreviousStep,
                onNextStep: this.handleNextStep,
                // onStart: this.handleTourStart,
                onStop: this.handleTourStop,
                onFinish: this.handleTourFinish,
                onSkip: this.handleTourSkip
            },
            tourSteps:[
                //header steps
                {
                    target: '#tour-start-btn',
                    content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#master-search',
                    content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    },
                },
                {
                    target: '#add-new-dropDown',
                    content: `<strong>Add New Record</strong><br>Enables you to add new orders, products and categories on the go.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#help-link',
                    content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#user-options-dropdown',
                    content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                //themes steps
                {
                    target: '#theme-name',
                    content: `<strong>Theme Name</strong><br>This represents name of the theme.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement:'top'
                    }
                },
                {
                    target: '#theme-selection',
                    content: `<strong>Theme Selection</strong><br>Theme can be selected by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement:'top'
                    }
                },
                {
                    target: '#theme-demo',
                    content: `<strong>Theme Demo</strong><br>Live demo of the theme can be accessed by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement:'top'
                    }
                },
                {
                    target: '#generate-theme-request',
                    content: `<strong>Generate Request</strong><br>Theme change request can be generated by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement:'right'
                    }
                },
            ],
            tourIsActive:false
        }
    },
    components:{
        subHeader,
        tutorialsAction,
        loaderBtn
    },
    methods:{
        selectTheme(theme){

            if(!this.tourIsActive){

                this.selectedTheme = theme;

            }

        },
        openVideoPreview(listedTheme){
            
            this.$refs.themeVideoDemoIframe.src = listedTheme.video_link;

            this.videoDialogVisible = true;

        },
        closeVideoPreviewHandle(){

            this.videoDialogVisible = false;
            
            this.$refs.themeVideoDemoIframe.src = '';

        },
        async themeChangeOperations(action){

            if(action == "generate-request"){

                if(this.selectedTheme.length == 0){

                    this.$message({
                        type: 'error',
                        showClose: false,
                        message: "A theme should be selected to generate a request.",
                    });

                    return

                }

                if(this.selectedTheme.id == this.settings.theme.id){

                    this.$message({
                        type: 'error',
                        showClose: false,
                        message: "This theme is currently in your use, choose a different one.",
                    });

                    return

                }

                MessageBox.confirm(
                    'Are you sure you want to generate a theme change request?',
                    'Warning',
                    {
                        type: 'warning',
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'Cancel',
                    }
                ).then(async ()=>{

                    let formData = new FormData();

                    formData.append('selected_theme_id',this.selectedTheme.id);

                    this.loader = true;
                    try{
                        let res = await this.$axios.post('theme/change/request/initiate',formData);
                        if(res.data.status_code == "1186"){

                            this.$notify({
                                type: 'success',
                                title: 'Success',
                                message: res.data.message,
                            });

                            this.selectedTheme = [];

                            this.$store.dispatch('settings_module/fetchStoreSetting');
                            
                        }
                    }catch(error){

                        if(error.response){

                            if(error.response.data.error.includes("doesn't support")){
                                
                                let subscriptionPath = window.location.origin + "/subscription";

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    dangerouslyUseHTMLString: true,
                                    message: error.response.data.error 
                                    + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                                });

                            }else{

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    message: error.response.data.message,
                                });

                            }

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.message,
                            });

                        }

                    }finally{

                        this.loader = false;

                    }

                }).catch(() => {})

            }else if(action == "cancel-request"){

                if(this.generatedRequest){

                    MessageBox.confirm(
                        'Are you sure you want to cancel the theme change request?',
                        'Warning',
                        {
                            type: 'warning',
                            confirmButtonText: 'Yes',
                            cancelButtonText: 'Cancel',
                        }
                    ).then(async ()=>{

                        let formData = new FormData();

                        formData.append('request_id',this.generatedRequest.id);

                        this.loader = true;
                        try{
                            let res = await this.$axios.post('/theme/change/request/cancel',formData);
                            if(res.data.status_code == '1189'){

                                this.$notify({
                                    type: 'success',
                                    title: 'Success',
                                    message: res.data.message,
                                });

                                this.$store.dispatch('settings_module/fetchStoreSetting');

                            }
                        }catch(error){

                            if(error.response){

                                if(error.response.data.error.includes('already been')){

                                    this.$message({
                                        type: 'error',
                                        showClose: true,
                                        message: error.response.data.error,
                                    });

                                }else if(error.response.data.error.includes("doesn't support")){
                                
                                    let subscriptionPath = window.location.origin + "/subscription";

                                    this.$message({
                                        type: 'error',
                                        showClose: true,
                                        dangerouslyUseHTMLString: true,
                                        message: error.response.data.error 
                                        + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                                    });

                                }else{

                                    this.$message({
                                        type: 'error',
                                        showClose: true,
                                        message: error.response.data.message,
                                    });

                                }

                            }else{

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    message: error.message,
                                });

                            }

                        }finally{

                            this.loader = false;

                        }

                    }).catch(() => {})

                }

            }else if(action == "update-theme"){

                this.$notify({
                    type: 'info',
                    title: 'Updating Theme',
                    message: "The theme update process may require some time. Please be patient.",
                });

                this.themeUpdateLoader = true;
                try{
                    
                    let res = await this.$axios.post('/store/theme/update');
                    if(res.data.status_code == "1153"){
                        
                        this.$notify({
                            type: 'success',
                            title: 'Success',
                            message: res.data.message,
                        });

                        this.$store.dispatch('settings_module/fetchStoreSetting');

                        this.$store.dispatch('themes_module/fetchThemesRequest');

                    }
                }catch(error){

                    if(error.response){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.message,
                        });

                    }
                }finally{

                    this.themeUpdateLoader = false;

                }

            }

        },
        //tour methods
        startThemeManagementTour(){

            if(this.themesList.length > 0){

                this.$tours.themeManagementTour.start();

                this.tourIsActive = true;

            }else{

                this.$notify({
                    type: 'error',
                    title: 'Themes Missing',
                    message: 'Unfortunately, there are no themes available right now.',
                });

            }

        },
        handleNextStep(currentStep){

        },
        handlePreviousStep(currentStep){

        },
        handleTourStop(){

            this.tourIsActive = false;

        },
        handleTourFinish(){

            this.tourIsActive = false;

        },
        handleTourSkip(){

            this.tourIsActive = false;

        },
    },
    computed:{
        ...mapGetters({
            themesList: 'themes_module/themes',
            settings:'settings_module/settings',
            settingsLoader: "settings_module/isPending",
            themesLoading: 'themes_module/isPending',
        }),
    },
    watch:{
        'settings':{
            handler: function(val){

                this.currentTheme = val.theme;
                // this.selectedTheme = val.theme
                this.generatedRequest = val.theme_change_requests;

            },
            deep:true
        },
        'themesList':{
            handler:function(value){

                this.availableThemes = value;

            },deep:true
        }
    },
    async beforeMount(){

        if(this.themesList.length == 0){

            this.$store.dispatch('themes_module/fetchThemesRequest');

        }else{

            this.availableThemes = this.themesList;
            
        }

        if(!this.settingsLoader){

            this.currentTheme = this.settings.theme;
            this.generatedRequest = this.settings.theme_change_requests;
            
        }

    }
}
</script>

<style>

</style>